const Works = [
    {
        id: 1,
        name: "Periodic Tables",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/PeriodicTables.png",
        background: "/assets/images/PeriodicTablesHero.png",
        headline:
            "A Web Application for Managing Restaurant Table Reservations",
        text: [
            "This was the summative full stack capstone project for Thinkful.com's 'Software Engineering Flex' curriculum.",
            "The goal of this project was to create a front end web application that could usefully create, read, update, and delete reservation and table information stored in a database using an API",
        ],
        date: "May 2021",
        technology: ["React", "Bootstrap", "Express", "Node", "PostgreSQL"],
        repository: "https://www.github.com/djdenney/Periodic_Tables",
        deployment:
            "https://periodic-tables-frontend-eosin.vercel.app/dashboard",
        imagesLeft: ["PeriodicTables1.png", "PeriodicTables3.png"],
        imagesRight: ["PeriodicTables2.png", "PeriodicTables4.png"],
    },
    {
        id: 2,
        name: "Flashcard-O-Matic",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/FlashcardOMatic.png",
        background: "/assets/images/FlashcardOMaticHero.png",
        headline:
            "A Web Application for Creating and Reviewing Study Materials",
        text: [
            "This was a summative frontend Capstone project for Thinkful.com's 'Software Engineering Flex' curriculum",
            "The goal of this project was to create a front end web application that used session storage and multiple unique pages with routes.",
        ],
        date: "March 2021",
        technology: ["React", "Bootstrap"],
        repository: "https://www.github.com/djdenney/Flashcard-O-Matic",
        deployment: "",
        imagesLeft: ["FlashcardOMatic1.png", "FlashcardOMatic3.png", "FlashcardOMatic5.png"],
        imagesRight: ["FlashcardOMatic2.png", "FlashcardOMatic4.png", "FlashcardOMatic6.png"],
    },
    {
        id: 3,
        name: "WeLoveMovies",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/WeLoveMovies.png",
        background: "/assets/images/WeLoveMoviesHero.png",
        headline:
            "An Express API Built to Support a Web Application for Documenting Movie Showtimes and Reviews",
        text: [
            "This was the summative backend capstone project for Thinkful.com's 'Software Engineering Flex' curriculum.",
            "The goal of this project was to create an API that could handle requests and responses for multiple routes from a relational database",
        ],
        date: "April 2021",
        technology: ["React", "Bootstrap", "Express", "Node", "PostgreSQL"],
        repository: "https://github.com/djdenney/WeLoveMovies",
        deployment:
            "https://backend-we-love-movies-a7joxuhag-djdenney.vercel.app/movies",
        imagesLeft: ["WeLoveMovies1.png", "WeLoveMovies3.png"],
        imagesRight: ["WeLoveMovies2.png", "WeLoveMovies4.png"],
    },
    {
        id: 4,
        name: "Pomodoro Timer",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/PomodoroTimer.png",
        background: "/assets/images/PomodoroTimerHero.png",
        headline:
            "A Web Application for Creating and Reviewing Study Materials",
        text: [
            "This was a formative frontend project for Thinkful.com's 'Software Engineering Flex' curriculum",
            "The goal of this project was to create a front end web application with logical boundaries and user inputs.",
        ],
        date: "March 2021",
        technology: ["HTML", "Bootstrap", "JavaScript"],
        repository: "https://www.github.com/djdenney/Pomodoro_Timer",
        deployment: "https://djdenney.github.io/Pomodoro_Timer/",
        imagesLeft: ["PomodoroTimer1.png", "PomodoroTimer3.png", "PomodoroTimer5.png"],
        imagesRight: ["PomodoroTimer2.png", "PomodoroTimer4.png", "PomodoroTimer6.png"],
    },
    {
        id: 5,
        name: "GrubDash",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/GrubDash.png",
        background: "/assets/images/GrubDashHero.png",
        headline:
            "An Express API to support a Web Application used to order food",
        text: [
            "This was a formative backend project for Thinkful.com's 'Software Engineering Flex' curriculum",
            "The goal of this project was to create a backend API that returns JSON data from local files. This Project is not connected to a database.",
        ],
        date: "March 2021",
        technology: ["Node", "Express"],
        repository: "https://www.github.com/djdenney/GrubDash",
        deployment: "https://grubdash-frontend.vercel.app/",
        imagesLeft: ["GrubDash1.png", "GrubDash3.png"],
        imagesRight: ["GrubDash2.png", "GrubDash4.png"],
    },
    {
        id: 6,
        name: "Decoder Ring",
        category: "Scholastic",
        fixedCategory: "Scholastic",
        image: "/assets/images/DecoderRing.png",
        background: "/assets/images/DecoderRingHero.png",
        headline:
            "A React Web Application used to encode and decode messages",
        text: [
            "This was a formative JavaScript project for Thinkful.com's 'Software Engineering Flex' curriculum",
            "The goal of this project was to make use of JavaScript methods to manipulate data.",
        ],
        date: "March 2021",
        technology: ["Javascript", "HTML", "CSS"],
        repository: "https://www.github.com/djdenney/Decoder_Ring",
        deployment: "https://djdenney.github.io/Decoder_Ring/",
        imagesLeft: ["DecoderRing1.png", "DecoderRing3.png"],
        imagesRight: ["DecoderRing2.png", "DecoderRing4.png"],
    },
];

export default Works;
